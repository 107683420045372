import React, { useState, useEffect, useRef } from "react"
import tw, { styled } from "twin.macro"
import Layout from "../components/layout"
import BlockContent from "@sanity/block-content-to-react"
import Footer from "../components/Footer"
import LogRocket from 'logrocket';
LogRocket.init('ziiqiw/studio-conscious');

const Policy = styled.div`
  line-height: 2;
  width: 75%;
  margin: 50px auto;
  @media (min-width: 1024px) {
    //height: 50vh;
  }
`

const Header = styled.h1`
  margin-top: 100px;
  text-align: center;
`

const Privacy = ({ location, data }) => {
  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }

  return (
    <>
      <Layout location={location}>
        <Header>Privacy Policy</Header>
        <Policy>
          <BlockContent
            blocks={data.sanityPrivacyPolicy._rawText}
            serializers={serializers}
          />
        </Policy>
        <Footer />
      </Layout>
    </>
  )
}

export default Privacy

export const query = graphql`
  query PrivacyQuery {
    sanityPrivacyPolicy {
      _rawText
    }
  }
`
